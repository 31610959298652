<template>
  <div class="p25">
    <div class="mb30 nobg_box block_base" v-if="isBatch">
      <van-steps :active="2" active-color="#007EC5">
        <van-step>货物信息</van-step>
        <van-step>选择产品</van-step>
        <van-step>信息确认</van-step>
      </van-steps>
    </div>
    <van-cell-group>
      <van-cell center>
        <template #title>
          <div
            class="ml20"
            v-if="newStepArr.sendersName && newStepArr.sendersPhone"
          >
            {{ newStepArr.sendersName || "" }}
            {{ newStepArr.sendersPhone || "" }}
          </div>
          <div class="ml20 f888" v-else>请填写发货信息</div>
        </template>
        <template #icon>
          <van-tag type="primary" class="size26">发</van-tag>
        </template>
        <template #label v-if="newStepArr.sendersCityName">
          <div class="ml20 fz28">
            {{ sendsAddr }}
          </div>
        </template>
      </van-cell>
      <van-cell center>
        <template #title>
          <div
            class="ml20"
            v-if="newStepArr.recipientName && newStepArr.recipientPhone"
          >
            {{ newStepArr.recipientName || "" }}
            {{ newStepArr.recipientPhone || "" }}
          </div>
          <div class="ml20 f888" v-else>请填写收货信息</div>
        </template>
        <template #icon>
          <van-tag type="success" class="size26">收</van-tag>
        </template>
        <template #label>
          <div class="ml20 fz28" v-if="newStepArr.recipientCityName">
            {{ receiptAddr }}
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group class="mt25 pb20 info">
      <van-cell
        title="发货时间"
        :value="newStepArr.sendersDate"
        :border="false"
      />
      <van-cell
        title="产品类型"
        :value="newStepArr.productTypeName"
        :border="false"
      />
      <van-cell
        title="货物信息"
        :value="newStepArr.cargoTypeName"
        :border="false"
      />
      <van-cell title="服务信息" :value="service" :border="false" />
      <van-cell title="备注" :value="newStepArr.remark" />
    </van-cell-group>
    <van-cell-group
      class="mt25 pb20 info price_list"
      v-if="$store.state.feeList.length"
    >
      <van-cell
        title="费用明细"
        :value="newStepArr.sendersDate"
        :border="false"
      />
      <van-cell
        v-for="(item, index) in $store.state.feeList"
        :key="index"
        :title="item.feeName"
        :value="item.cost + '元'"
        :border="false"
      />
    </van-cell-group>
    <van-cell-group class="mt25">
      <van-cell title="支付方式">
        <template #right-icon>
          <span
            v-for="item in $store.state.byPCode.paymentTypeVO"
            :key="item.code"
          >
            <span
              class="ml20 pay_li"
              v-if="!(item.code === 'ZL1605223' && $store.monthClosing != 1)"
              :key="item.code"
              :code="item.code"
              :class="{ pay_li_cur: newStepArr.paymentType === item.code }"
              @click="optionClick(item.code, item.name, 'paymentType')"
              >{{ item.name }}
            </span>
          </span>
        </template>
      </van-cell>
      <van-cell title="优惠方式" @click="handleModal">
        <template #right-icon>
          {{ newStepArr.couponName || "请选择" }}
          <van-icon name="arrow-down" class="mt10 ml10" />
        </template>
      </van-cell>
    </van-cell-group>
    <div class="flex_c_m mt45">
      <div
        class="subbtn"
        v-if="!isBatch && newStepArr.paymentType === 'ZL1605220'"
        @click="() => payOrder('toList')"
      >
        提货支付
      </div>
      <div
        class="subbtn"
        v-if="!isBatch && newStepArr.paymentType === 'ZL1605220'"
        @click="() => payOrder('toPay')"
      >
        立即支付
      </div>
      <div
        class="subbtn"
        v-if="!isBatch && newStepArr.paymentType !== 'ZL1605220'"
        @click="() => payOrder('toList')"
      >
        {{ newStepArr.orderSn ? "修改订单" : "确认下单" }}
      </div>
      <div class="subbtn" v-if="isBatch" @click="payBatchOrder">确定</div>
    </div>
    <CouponModal
      v-if="showCouponModal"
      ref="refMCoupon"
      :showCouponModal="showCouponModal"
      :stepFourArr="newStepArr"
      @changeFaCoupon="changeFaCoupon"
    ></CouponModal>
  </div>
</template>
<script>
import CouponModal from "@/components/couponsCmp/CouponModal.vue";
import {
  getOrderForm,
  setOrderForm,
  getBatchOrderForm,
  setBatchOrderForm,
} from "@/assets/js/storage";
import {
  calcTotal,
  getDetailStr,
  getByCode,
  submitOrderForm,
} from "@/assets/js/order";
export default {
  components: { CouponModal },
  data() {
    return {
      isBatch: false,
      faIdx: -1,
      newStepArr: {},
      showCouponModal: false, //是否展示优惠券弹窗
    };
  },
  created() {
    getByCode(this);
    let allDatas = this.$route.params;
    if (allDatas.oneDatas) {
      this.newStepArr = allDatas.oneDatas;
      this.isBatch = allDatas.isBatch || false;
      this.faIdx = allDatas.faIdx;
      calcTotal(this, allDatas.oneDatas, this.isBatch, this.faIdx, true);
    } else {
      this.$router.push({
        path: "/m_payorder",
      });
    }
  },
  computed: {
    sendsAddr() {
      let addr =
        (this.newStepArr.sendersProvinceName || "") +
        "" +
        (this.newStepArr.sendersCityName || "") +
        "" +
        (this.newStepArr.sendersDistrictName || "") +
        "" +
        (this.newStepArr.sendersDetailaddress || "");
      return addr;
    },
    receiptAddr() {
      let addr =
        (this.newStepArr.recipientProvinceName || "") +
        "" +
        (this.newStepArr.recipientCityName || "") +
        "" +
        (this.newStepArr.recipientDistrictName || "") +
        "" +
        (this.newStepArr.recipientDetailaddress || "");
      return addr;
    },
    service() {
      let str = getDetailStr(this.newStepArr, true);
      return str;
    },
  },
  methods: {
    changeCoupon() {
      getOrderValidCoupons(this, this.newStepArr); //, isBatch, faIdx
    },
    handleModal() {
      if (!!this.newStepArr.orderSn && this.newStepArr.dzdState === "0") {
        this.$toast("锁定订单，不能修改优惠方式");
        return;
      }
      this.showCouponModal = true;
      let newForm = getOrderForm();
      this.$refs.refMCoupon &&
        this.$refs.refMCoupon.changeVisible(true, newForm);
    },
    changeFaCoupon(couponNumArr, couponNameArr) {
      this.newStepArr.couponNum = couponNumArr.join(",");
      this.newStepArr.couponName = couponNameArr.join(",");
      this.showCouponModal = false;
    },
    optionClick(code, name, type) {
      if (!!this.newStepArr.orderSn && this.newStepArr.dzdState === "0") {
        this.$toast("锁定订单，不能修改支付方式");
        return;
      }
      this.newStepArr[type] = code;
      this.newStepArr[type + "Name"] = name;
      calcTotal(this, this.newStepArr, this.isBatch, this.faIdx, true);
    },
    payOrder(type) {
      submitOrderForm(this, this.newStepArr, type, this.isBatch, this.faIdx);
    },
    payBatchOrder() {
      let allDatas = getBatchOrderForm();
      allDatas.shList[this.faIdx] = {
        ...allDatas.shList[this.faIdx],
        ...this.newStepArr,
      };
      setBatchOrderForm(allDatas);
      submitOrderForm(this, this.newStepArr, "", this.isBatch, this.faIdx);
      this.$router.push({
        name: "m_batchorder",
        params: {
          allBatchDatas: allDatas,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.size26 {
  font-size: 26px;
  padding: 5px;
  line-height: 34px;
  border-radius: 5px;
}
.p25 {
  padding: 25px;
}
.mt25 {
  margin-top: 25px;
}
.info ::v-deep .van-cell {
  padding-bottom: 0;
  line-height: 24px;
}
.price_list ::v-deep .van-cell:first-child,
.price_list ::v-deep .van-cell:nth-last-child(2) {
  padding-bottom: 10px;
  border-bottom: 1px dashed #dbdcdb;
}
.pay_li {
  display: inline-block;
  min-width: 128px;
  padding: 0 10px;
  margin-left: 10px;
  line-height: 52px;
  font-size: 26px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border: 1px solid #e8e9e8;
  border-radius: 12px;
  box-sizing: border-box;
}
.pay_li_cur {
  background-color: #007ec5;
  color: #fff;
  border-color: #007ec5;
}
.subbtn {
  width: 260px;
  margin: 0 20px;
  line-height: 90px;
  font-size: 32px;
  color: #fff;
  background-color: #007ec5;
  border-radius: 90px;
  text-align: center;
}
.mt45 {
  margin-top: 45px;
}
</style>